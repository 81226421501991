input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.block-important {
  display: block !important;
}

.flex-important {
  display: flex !important;
}

/* Uncomment these lines to debug scrollbar behaviour on mac */
/* *::-webkit-scrollbar {
  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 11px;
}

*::-webkit-scrollbar:horizontal {
  height: 11px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

*::-webkit-scrollbar-track { 
  background-color: #fff; 
  border-radius: 8px; 
}  */
