.button-xs {
  min-width: 1px !important;
}

.button-sm {
  min-width: 1px !important;
}

.button-md {
  min-width: 1px !important;
}

.button-lg {
  min-width: 1px !important;
}

.button-xl {
  min-width: 1px !important;
}

.bg-white-important {
  background-color: white !important;
}

.dropdown {
  right: 72px;
}

@media (max-width: 768px) {
  .dropdown {
    right: 27px;
  }
}
